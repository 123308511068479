import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function CircuitCounter({ data, searchParams, setSearchParams }) {
    const currentParams = Object.fromEntries([...searchParams]);
    const isShowingAllData = currentParams.status !== 'Active' && currentParams.status !== 'Deactive' && currentParams.status !== 'Terminated' && currentParams.status !== 'Under Termination' && currentParams.status !== 'Under Maintenance';

    // Define a loading state (for demonstration purposes, replace with your actual loading logic)
    const loading = !data;

    return (
        <>
            <div className='desktop'>
                <div className="d-flex mt-3 overFlowXauto">
                    <div className="pointer docketCounterStyle">
                        <div className={`card widget widget-stats ${isShowingAllData && 'count-box-select'}`}>
                            <div className="card-body position-relative">
                                <div className="widget-stats-container d-flex">
                                    <div className="widget-stats-icon widget-stats-icon-primary">
                                        {loading ? <Skeleton circle={true} height={40} width={40} /> : <i className="material-icons-outlined">view_list</i>}
                                    </div>
                                    <div className="widget-stats-content flex-fill" onClick={() => setSearchParams('')}>
                                        <span className="widget-stats-title">Total Circuits</span>
                                        <span className="widget-stats-amount">{loading ? <Skeleton width={100} /> : data?.total ?? '-'}</span>
                                    </div>
                                    <div className="widget-stats-indicator widget-stats-indicator-positive align-self-start align-rb" onClick={() => setSearchParams('status=Active')}>
                                        {loading && <i className="material-icons">done</i>} {loading ? <Skeleton width={60} /> : `${data?.active ?? '-'} Active`}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pointer docketCounterStyle" onClick={() => setSearchParams('status=Under Maintenance')}>
                        <div className={`card widget widget-stats ${currentParams.status === 'Under Maintenance' && 'count-box-select'}`}>
                            <div className="card-body">
                                <div className="widget-stats-container d-flex">
                                    <div className="widget-stats-icon widget-stats-icon-danger">
                                        {loading ? <Skeleton circle={true} height={40} width={40} /> : <i className="material-icons-outlined">settings</i>}
                                    </div>
                                    <div className="widget-stats-content flex-fill">
                                        <span className="widget-stats-title">Under Maintenance</span>
                                        <span className="widget-stats-amount">{loading ? <Skeleton width={100} /> : data?.under_maintenance ?? '-'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pointer docketCounterStyle" onClick={() => setSearchParams('status=Under Termination')}>
                        <div className={`card widget widget-stats ${currentParams.status === 'Under Termination' && 'count-box-select'}`}>
                            <div className="card-body">
                                <div className="widget-stats-container d-flex">
                                    <div className="widget-stats-icon widget-stats-icon-danger">
                                        {loading ? <Skeleton circle={true} height={40} width={40} /> : <i className="material-icons-outlined">schedule</i>}
                                    </div>
                                    <div className="widget-stats-content flex-fill">
                                        <span className="widget-stats-title">Under Termination</span>
                                        <span className="widget-stats-amount">{loading ? <Skeleton width={100} /> : data?.under_termination ?? '-'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pointer docketCounterStyle" onClick={() => setSearchParams('status=Terminated')}>
                        <div className={`card widget widget-stats ${currentParams.status === 'Terminated' && 'count-box-select'}`}>
                            <div className="card-body">
                                <div className="widget-stats-container d-flex">
                                    <div className="widget-stats-icon widget-stats-icon-warning">
                                        {loading ? <Skeleton circle={true} height={40} width={40} /> : <i className="material-icons-outlined">power_settings_new</i>}
                                    </div>
                                    <div className="widget-stats-content flex-fill">
                                        <span className="widget-stats-title">Terminated</span>
                                        <span className="widget-stats-amount">{loading ? <Skeleton width={100} /> : data?.terminated ?? '-'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobile mt-2'>
                <div className='row m-0'>
                    <div className="shadow-sm mx-1 bg-white rounded col p-2">
                        <div className='mobileDocket' onClick={() => setSearchParams('')}>
                            <h5>TOTAL</h5>
                            <h3 className='text-success'>{loading ? <Skeleton width={50} /> : data?.total ?? '-'}</h3>
                        </div>
                    </div>
                    <div className="shadow-sm mx-1 bg-white rounded col p-2">
                        <div className='mobileDocket' onClick={() => setSearchParams('status=Active')}>
                            <h5>ACTIVE</h5>
                            <h3 className='orangeColor'>{loading ? <Skeleton width={50} /> : data?.active ?? '-'}</h3>
                        </div>
                    </div>
                    <div className="shadow-sm mx-1 bg-white rounded col p-2">
                        <div className='mobileDocket' onClick={() => setSearchParams('status=Under Maintenance')}>
                            <h5>Under Maint.</h5>
                            <h3 className='text-info'>{loading ? <Skeleton width={50} /> : data?.under_maintenance ?? '-'}</h3>
                        </div>
                    </div>
                    <div className="shadow-sm mx-1 bg-white rounded col p-2">
                        <div className='mobileDocket' onClick={() => setSearchParams('status=Terminated')}>
                            <h5>Terminated</h5>
                            <h3 className='text-danger'>{loading ? <Skeleton width={50} /> : data?.terminated ?? '-'}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
