import { Container } from '@mui/material';
import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import PageBreadcrumbs from '../../components/Common/Breadcrumbs';
import DocketForm from '../../components/Forms/DocketForm';
import { useCircuitDetail } from '../../hooks/circuit';
import { useDocket, useDocketDetail } from '../../hooks/docket';
import DocketInfoModal from '../../components/Models/DocketInfoModal';
import toast from 'react-hot-toast';

export default function DocketUpdatePage(props) {
    let navigate = useNavigate();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const { docketData, docketIsLoading } = useDocketDetail(id);
    const { circuitData, circuitIsLoading } = useCircuitDetail(docketData?.data?.circuit);
    const { handleDocketAction, docketActionIsLoading } = useDocket('PATCH');
    const [modal, setModal] = useState(false)
    // console.log(docketData)
    // console.log(currentParams?.status)

    const handleSubmit = async (values, { resetForm }) => {
        const toastId = toast.loading('Processing...');
        const { fiber_team_leader, dtechnician, third_party_docket_no, problem, dhirer_name, comment, docketed_by, docketed_by_contact, dstatus, circuit, is_scheduled, scheduled_at } = values;
        // console.log(values)
        const res = await handleDocketAction({
            "fiber_team_leader": fiber_team_leader,
            "technician": dtechnician,
            "third_party_docket_no": third_party_docket_no,
            "problem": problem,
            "hirer_name": dhirer_name,
            "comment": comment,
            "docketed_by": docketed_by,
            "docketed_by_contact": docketed_by_contact,
            // "status": currentParams?.status ?? dstatus,
            "history": {},
            "circuit": circuit,
            "id": id,
            // "provider": 1

            is_scheduled: is_scheduled,
            scheduled_at: is_scheduled ? scheduled_at : null,
            status: is_scheduled ? 'Scheduled' : currentParams?.status ?? dstatus,
        });

        if (res.success) {
            toast.success(`${docketData?.data?.code} - ${res.detail}`, { id: toastId });
            navigate(`/docket`);
            setModal(true)
            if (currentParams?.status){
                navigate(`/docket/${docketData?.data?.code}`);
            }
        } else {
            toast.error(res.detail, { id: toastId });
        }
        // console.log('data --> ', res);
    }
    return (
        <>
            <Helmet>
                <title>Dockets - Docket Update</title>
            </Helmet>
            <Container maxWidth="xxl" disableGutters>

                <PageBreadcrumbs
                    title="Docket Update"
                    alt='Update'
                    history={[{ name: 'Docket', to: '/docket' }, { name: id, to: `/docket/${id}` },]}
                />

                <div className='container'>
                    <div className="row ">
                        <div className="col mt-2">
                            {circuitData && !circuitIsLoading && <DocketForm
                                data={circuitData.data}
                                docketData={docketData?.data}
                                isLoading={docketActionIsLoading}
                                handleSubmit={handleSubmit}
                                initialForm={6}
                                submitBtnLabel={currentParams?.status ? 'Modify Docket': 'Update Docket' }
                            />}
                        </div>
                    </div>

                </div>
                <div className='container'>

                    <div className="row ">
                        <div className="col mt-2">
                            {/* {console.log(docketData)} */}
                            {docketData && !docketIsLoading && <DocketInfoModal
                                modal={modal}
                                setModal={setModal}
                                docData={docketData?.data}
                            />}
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}
