import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { formatUserOptionsList, useUsersList, useUsersListLite } from '../../../hooks/user';
import SelectField from '../../Common/Fields/SelectField';
import TextField from '../../Common/Fields/TextField';
import { useFilterUsers } from '../../../hooks/user/roles';

export default function ContactDetail({ formik, disabled }) {
    const { filterUsers, isFilterIsLoading } = useFilterUsers()
    const ti = useUsersListLite('?user_role=TI');  // Territory Incharge *
    const ac_manager = useUsersListLite('?user_role=AM');  // Account Manager *
    const fiber_tl = useUsersListLite('?user_role=FTL');  // Fiber Team Leader *
    const feasibilities = useUsersListLite('');  // Fiber Team Leader *
    const [hiDatas, setHIDatas] = useState([]) // Hub Incharge *
    const [teDatas, setTeDatas] = useState([]) // Technicain *
    const [ftDatas, setFtDatas] = useState([]) // Fiber Technician *
    // console.log(ac_manager)
    const handleTIChange = async (value) => {
        // Territory Incharge Change
        formik.setFieldValue('territory_incharge', value)
        const data = await filterUsers(`?user_role=HI&parent_role=${value}`)
        // setHIDatas(data)
        data.success && setHIDatas(data.data)
    }
    const handleHIChange = async (value) => {
        // Hub Incharge Change
        formik.setFieldValue('hub_incharge', value)
        const data = await filterUsers(`?user_role=TE&parent_role=${value}`)
        // setTeDatas(data)
        data.success && setTeDatas(data.data)
    }
    const handleFTLChange = async (value) => {
        // Fiber Team Leader Change
        formik.setFieldValue('fiber_vendor_team_leader', value)
        const data = await filterUsers(`?user_role=FT&parent_role=${value}`)
        // console.log(data)
        data.success && setFtDatas(data.data)
    }
    useEffect(() => {
        formik.values.territory_incharge && handleTIChange(formik.values.territory_incharge)
        formik.values.hub_incharge && handleHIChange(formik.values.hub_incharge)
        formik.values.fiber_vendor_team_leader && handleFTLChange(formik.values.fiber_vendor_team_leader)
    }, [])
    return (
        <div className="card">
            <div className="card-body stepFormDetails">
                <div className="row">
                    <div className='col-12'>
                        <h3 className='text-center display-6 mb-4 text-primary' style={{ 'fontSize': '22px' }}>CONTACT DETAILS</h3>
                    </div>

                    <div className="col-md-6">
                        <TextField label='LC. Contact Person' type="text" disabled={disabled} name='lc_contact_persion' placeholder='Local Contact Person *' sx={{ mb: 3 }} />
                    </div>
                    <div className="col-md-6">
                        <TextField label='LC. Mobile No' type="text" disabled={disabled} name='lc_mobile_no' placeholder='Local. Mobile No *' sx={{ mb: 3 }} />
                    </div>
                    <div className='col-md-3'>
                        <Typography variant='caption'>Territory Incharge <span className='req'>*</span></Typography>

                        <SelectField
                            name='territory_incharge'
                            options={ti?.usersData?.data?.map(formatUserOptionsList)}
                            value={ti?.usersData?.data?.map(formatUserOptionsList).filter(function (option) {
                                return option.value === formik.values.territory_incharge;
                            })}
                            onChange={(e) => handleTIChange(e.value)}
                            isLoading={ti?.usersIsLoading}
                            isDisabled={disabled}
                        />
                    </div>
                    <div className='col-md-3'>
                        <Typography variant='caption'>Hub Incharge </Typography>
                        <SelectField
                            name='hub_incharge'
                            options={hiDatas.map(formatUserOptionsList)}
                            value={hiDatas.map(formatUserOptionsList).filter(function (option) {
                                return option.value === formik.values.hub_incharge;
                            })}
                            onChange={(e) => handleHIChange(e.value)}
                            isLoading={isFilterIsLoading}
                            isDisabled={hiDatas.length === 0 || disabled}
                            
                        />
                    </div>

                    <div className='col-md-3'>
                        <Typography variant='caption'>Field Engineer (Technician) </Typography>

                        <SelectField
                            name='technician'
                            options={teDatas.map(formatUserOptionsList)}
                            value={teDatas.map(formatUserOptionsList).filter(function (option) {
                                return option.value === formik.values.technician;
                            })}
                            onChange={(e) => formik.setFieldValue('technician', e.value)}
                            isLoading={isFilterIsLoading}
                            isDisabled={hiDatas.length === 0 || disabled}
                            // isClearable
                        />
                        {/* {usersData && !usersIsLoading ? <SelectField
                            name='technician'
                            options={usersData.map(formatUserOptionsList)}
                            value={usersData.map(formatUserOptionsList).filter(function (option) {
                                return option.value === formik.values.technician;
                            })}
                            onChange={(e) => formik.setFieldValue('technician', e.value)}

                        /> : <Loader2 color="inherit" />} */}
                    </div>


                    <div className='col-md-3'>
                        <Typography variant='caption'>A/C Manager <span className='req'>*</span></Typography>

                        {/* {ac_manager.usersData && !ac_manager.usersIsLoading ? <SelectField
                            name='ac_manager'
                            options={ac_manager.usersData.map(formatUserOptionsList)}
                            value={ac_manager.usersData.map(formatUserOptionsList).filter(function (option) {
                                return option.value === formik.values.ac_manager;
                            })}
                            onChange={(e) => formik.setFieldValue('ac_manager', e.value)}

                        /> : <Loader2 color="inherit" />}  */}
                        <SelectField
                            name='ac_manager'
                            options={ac_manager?.usersData?.data?.map(formatUserOptionsList)}
                            value={ac_manager?.usersData?.data?.map(formatUserOptionsList).filter(function (option) {
                                return option.value === formik.values.ac_manager;
                            })}
                            onChange={(e) => formik.setFieldValue('ac_manager', e.value)}
                            isLoading={ac_manager?.usersIsLoading}
                            isDisabled={disabled}
                        />
                    </div>
                </div>

                <div className="col-md-12 mb-1 mt-1">
                    <TextField label='Service Assurance' type="text" disabled={disabled} name='service_assurance' placeholder='Service Assurance' sx={{ mt: 3 }} />
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        {/* <Typography>Fiber[Vendor]Team Leader <span className='req'>*</span></Typography> */}
                        <Typography variant='caption'>Fiber Team Leader <span className='req'>*</span></Typography>
                        <SelectField
                            name='fiber_vendor_team_leader'
                            options={fiber_tl?.usersData?.data?.map(formatUserOptionsList)}
                            value={fiber_tl?.usersData?.data?.map(formatUserOptionsList).filter(function (option) {
                                return option.value === formik.values.fiber_vendor_team_leader;
                            })}
                            onChange={(e) => handleFTLChange(e.value)}
                            isLoading={fiber_tl?.usersIsLoading}
                            isDisabled={disabled}
                        />

                        {/* {usersData && !usersIsLoading ? <SelectField
                            name='fiber_vendor_team_leader'
                            options={usersData.map(formatUserOptionsList)}
                            value={usersData.map(formatUserOptionsList).filter(function (option) {
                                return option.value === formik.values.fiber_vendor_team_leader;
                            })}
                            onChange={(e) => formik.setFieldValue('fiber_vendor_team_leader', e.value)}

                        /> : <Loader2 color="inherit" />} */}
                    </div>
                    <div className='col-md-4'>
                        {/* <Typography>FE | RF Team <span className='req'>*</span></Typography> */}
                        <Typography variant='caption'>Fiber Technician </Typography>

                        <SelectField
                            name='fe_rf_team'
                            options={ftDatas.map(formatUserOptionsList)}
                            value={ftDatas.map(formatUserOptionsList).filter(function (option) {
                                return option.value === formik.values.fe_rf_team;
                            })}
                            onChange={(e) => formik.setFieldValue('fe_rf_team', e.value)}
                            isLoading={isFilterIsLoading && ftDatas.length === 0}
                            isDisabled={ftDatas.length === 0 || disabled}
                        />

                        {/* {usersData && !usersIsLoading ? <SelectField
                            name='fe_rf_team'
                            options={usersData.map(formatUserOptionsList)}
                            value={usersData.map(formatUserOptionsList).filter(function (option) {
                                return option.value === formik.values.fe_rf_team;
                            })}
                            onChange={(e) => formik.setFieldValue('fe_rf_team', e.value)}

                        /> : <Loader2 color="inherit" />} */}
                    </div>
                    <div className='col-md-4'>
                        <Typography variant='caption'>Feasibility Done By <span className='req'>*</span></Typography>
                        <SelectField
                            name='feasibility_done_by'
                            options={feasibilities?.usersData?.data?.map(formatUserOptionsList)}
                            value={feasibilities?.usersData?.data?.map(formatUserOptionsList).filter(function (option) {
                                return option.value === formik.values.feasibility_done_by;
                            })}
                            onChange={(e) => formik.setFieldValue('feasibility_done_by', e.value)}
                            isLoading={feasibilities?.usersIsLoading}
                            isDisabled={disabled}
                        />

                        {/* {usersData && !usersIsLoading ? <SelectField
                            name='feasibility_done_by'
                            options={usersData.map(formatUserOptionsList)}
                            value={usersData.map(formatUserOptionsList).filter(function (option) {
                                return option.value === formik.values.feasibility_done_by;
                            })}
                            onChange={(e) => formik.setFieldValue('feasibility_done_by', e.value)}

                        /> : <Loader2 color="inherit" />} */}
                    </div>
                </div>
            </div>
        </div>

    )
}
