import React from 'react'
import Datetime from 'react-datetime';
import moment from 'moment';
import { formatDateTime } from '../../../utils/shortcuts';

const isValidDate = (current) => {
    return current.isSameOrAfter(moment(), 'day');
};
export default function ScheduleDetail({ formik, disabled }) {
    
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className='col-12'>
                        <h3 className='text-center display-6 mb-4 text-primary' style={{ 'fontSize': '22px' }}>SCHEDULE DOCKET</h3>
                    </div>


                    <div className="col-md-12 mb-4">
                        <Datetime
                            value={moment(formik.values.scheduled_at)} // Pass the selected date as value
                            onChange={(e) => formik.setFieldValue('scheduled_at', formatDateTime(e, 'YYYY-MM-DD H:m:0'))}
                            name='scheduled_at'
                            dateFormat="DD/MM/YYYY"
                            timeFormat="H:mm"
                            inputProps={{ // Set input properties
                                placeholder: 'Select Publishing Date', // Placeholder text

                            }}
                            isValidDate={isValidDate} // Disable past dates
                        />
                    </div>
                    {/* <div className="col-md-6 mt-0">
                        <TextField label='Docketed By *' type="text" name='docketed_by' placeholder='Docketed By' sx={{ mb: 3 }} />
                    </div> */}

                </div>
            </div>
        </div>

    )
}
