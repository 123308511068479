import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useCircuitAssets } from '../../hooks/circuit';
import { toast } from 'react-toastify';


// File size limit: 3MB (in bytes)
const FILE_SIZE_LIMIT = 3 * 1024 * 1024; // 3MB

// Allowed file types
const SUPPORTED_FORMATS = ['image/jpeg', 'image/png'];

// Validation Schema
const FileUploadSchema = Yup.object().shape({
    file: Yup.mixed()
        .required('A file is required')
        .test('fileSize', 'File size must be less than 3MB', (value) => {
            return value && value.size <= FILE_SIZE_LIMIT;
        })
        .test('fileType', 'Only JPG and PNG files are allowed', (value) => {
            return value && SUPPORTED_FORMATS.includes(value.type);
        })
});

const FileUploadForm = ({ setModal, circuitData }) => {

    const { handleAssetUpload, isUploading } = useCircuitAssets();
    const handleUpload = async (values, { setSubmitting, resetForm }) => {
  
        // Handle file upload
        const formData = new FormData();
        formData.append("file", values?.file);
        formData.append("circuit", parseInt(values?.circuit));
        const res = await handleAssetUpload(formData);
        if (res.success) {
            resetForm({ values: { file: null, circuit: circuitData?.id } });
            toast.success(res.detail);
        } else {
            toast.error(res.detail)
        }

    }
    return (
        <div className="d-flex justify-content-center align-items-center vh-10">
            <Formik
                initialValues={
                    {
                        file: null,
                        circuit: circuitData?.id
                    }
                }
                validationSchema={FileUploadSchema}
                onSubmit={handleUpload}
            >
                {({ setFieldValue }) => (
                    <Form className="w-20">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label htmlFor="file">Upload File</label>
                                    <Field name="file">
                                        {({ field }) => (
                                            <input
                                                disabled={isUploading}
                                                type="file"
                                                id="file"
                                                name="file"
                                                className="form-control"
                                                accept=".jpg,.jpeg,.png"
                                                onChange={(event) => {
                                                    setFieldValue("file", event.currentTarget.files[0]);
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="file" component="div" className="text-danger" />
                                </div>
                            </div>
                            <div className="col-md-4 d-flex align-items-end">
                                <button type="submit" className="btn btn-dark btn-sm" disabled={isUploading}>
                                    <i className="fa fa-upload text-sm" aria-hidden="true"></i> Upload
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default FileUploadForm;
