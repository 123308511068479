import React from 'react'
import { useLastDocketInfo } from '../../../hooks/docket/latest'
import { useDebouncedCallback } from 'use-debounce'

export default function LastDocketInfo({ circuit_id }) {
    const [apiEnabled, setApiEnabled] = React.useState(false)
    const { lastDocketData, fetchLastDocket } = useLastDocketInfo('', apiEnabled)

    const fetchData = useDebouncedCallback(() => {
        setApiEnabled(true)
        fetchLastDocket(`/${circuit_id}`)
    }, 600)
    
    React.useEffect(() => {
        fetchData()
    }, [circuit_id])
    return (
        <div>
            {lastDocketData?.success && lastDocketData?.data?.close_note ? <div className='row bg-dark py-2 mt-3'>
                <p className='text-center mb-0 text-light'>Closing Note: {lastDocketData?.data?.close_note}</p>
            </div> : ''}
        </div>
    )
}
