import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { formatSelectOptionsList } from '../../../hooks/circuit';
import SelectField from '../../Common/Fields/SelectField';
import TextField from '../../Common/Fields/TextField';
import { formatUserOptionsDetailList, formatUserOptionsList, useTeamList, useUsersListLite } from '../../../hooks/user';
import { useRFOList } from '../../../hooks/docket';
import { useFilterUsers } from '../../../hooks/user/roles';

export default function BasicDocket({ formik, data, disabled }) {
    const { filterUsers, isFilterIsLoading } = useFilterUsers()
    const { rfosData, rfosIsLoading } = useRFOList();
    const { teamsData, teamsIsLoading } = useTeamList();
    const docketed_by = useUsersListLite('');
    const [ftDatas, setFtDatas] = useState([]) // Fiber Technician *

    const getTechnicians = async (value) => {
        // const data = await filterUsers(`?user_role=FT&parent_role=${value}`)
        const data = await filterUsers(`?all_fiber=true`)
        data.success && setFtDatas(data.data)
    }
    useEffect(() => {
        data.docket.fiber_team_leader && getTechnicians(data.docket.fiber_team_leader)
    }, [])
    return (
        <div className="card">
            <div className="card-body">
                {/* Technician */}
                <div className="row">
                    <div className='col-12'>
                        <h3 className='text-center display-6 mb-4 text-primary' style={{ 'fontSize': '22px' }}>BASIC DOCKET</h3>
                    </div>
                    <div className="col-md-4">
                        <Typography>Technician 1 {formik.values.technician && <small className='text-primary' role='button' onClick={() => formik.setFieldValue('technician', null)}>Clear</small>}</Typography>

                        <SelectField
                            name='technician'
                            options={ftDatas.map(formatUserOptionsList)}
                            value={ftDatas.map(formatUserOptionsList).filter(function (option) {
                                return option.value === formik.values.technician;
                            })}
                            onChange={(e) => formik.setFieldValue('technician', e.value)}
                            isLoading={isFilterIsLoading && ftDatas.length === 0}
                            // isDisabled={ftDatas.length === 0}
                            isDisabled={disabled}
                        />

                    </div>
                    <div className="col-md-4">
                        <Typography>Technician 2 {formik.values.technician2 && <small className='text-primary' role='button' onClick={() => formik.setFieldValue('technician2', null)}>Clear</small>}</Typography>

                        <SelectField
                            name='technician2'
                            options={ftDatas.map(formatUserOptionsList)}
                            value={ftDatas.map(formatUserOptionsList).filter(function (option) {
                                return option.value === formik.values.technician2;
                            })}
                            onChange={(e) => formik.setFieldValue('technician2', e.value)}
                            isLoading={isFilterIsLoading && ftDatas.length === 0}
                            // isDisabled={ftDatas.length === 0}
                            isDisabled={disabled}
                        />
                    </div>
                    <div className="col-md-4">
                        <Typography>Technician 3 {formik.values.technician3 && <small className='text-primary' role='button' onClick={() => formik.setFieldValue('technician3', null)}>Clear</small>}</Typography>

                        <SelectField
                            name='technician3'
                            options={ftDatas.map(formatUserOptionsList)}
                            value={ftDatas.map(formatUserOptionsList).filter(function (option) {
                                return option.value === formik.values.technician3;
                            })}
                            onChange={(e) => formik.setFieldValue('technician3', e.value)}
                            isLoading={isFilterIsLoading && ftDatas.length === 0}
                            isDisabled={disabled}
                        />
                    </div>
                </div>
                {/* Vehicle */}

                <div className="row mt-3">
                    <div className='col-md-4'>
                        <Typography>Confirmed By</Typography>
                        <SelectField
                            name='docketed_by_not_using'
                            options={docketed_by?.usersData?.data?.map(formatUserOptionsDetailList)}
                            value={docketed_by?.usersData?.data?.map(formatUserOptionsDetailList).filter(function (option) {
                                return option.account_name === formik.values.confirmed_by;
                            })}
                            onChange={(e) => {
                                formik.setFieldValue('confirmed_by', e.account_name)
                                // formik.setFieldValue('docketed_by_contact', e.mobile)
                            }}
                            isLoading={docketed_by?.usersIsLoading}
                        />
                    </div>
                    <div className="col-md-4">
                        <Typography>RFO <span className='req'>*</span></Typography>

                        <SelectField
                            name='rfo'
                            options={rfosData?.data.map(formatSelectOptionsList)}
                            value={rfosData?.data.map(formatSelectOptionsList).filter(function (option) {
                                return option.value === formik.values.rfo;
                            })}
                            onChange={(e) => formik.setFieldValue('rfo', e.value)}
                            isDisabled={disabled}
                            isLoading={rfosIsLoading}

                        />
                    </div>
                    <div className="col-md-4">
                        <Typography>Workdone By <span className='req'>*</span></Typography>

                        <SelectField
                            name='workdone_by'
                            options={teamsData?.data.map(formatSelectOptionsList)}
                            value={teamsData?.data.map(formatSelectOptionsList).filter(function (option) {
                                return option.value === formik.values.workdone_by;
                            })}
                            onChange={(e) => formik.setFieldValue('workdone_by', e.value)}
                            isDisabled={disabled}
                            isLoading={teamsIsLoading}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <TextField label='Confirmed By *' disabled={disabled} type="text" name='confirmed_by' placeholder='Enter Confirmed By' sx={{ mb: 3 }} />
                    </div>
                    <div className="col-md-6">
                        <TextField label='Comments *' disabled={disabled} type="text" name='comments' placeholder='Enter Comments' sx={{ mb: 3 }} />
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-md-12">
                        <TextField label='Closing Note (Optional)' disabled={disabled} type="text" name='close_note' placeholder='Enter Closing Note' sx={{ mb: 3 }} />
                    </div>
                  
                </div>
            </div>
        </div>
    )
}
