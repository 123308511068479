import 'react-toastify/dist/ReactToastify.css';
import "react-datetime/css/react-datetime.css";
import 'react-loading-skeleton/dist/skeleton.css'
import './App.css';
import './index.css';

import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AppRoutes from './routes/AppRoutes';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import ProviderState from './context/provider';
import CoreStates from './context/core';
import { Toaster } from 'react-hot-toast';

const queryClient = new QueryClient();
Modal.setAppElement('#root');

function App() {
    return (
        <ProviderState>

            <QueryClientProvider client={queryClient}>
                <CoreStates>
                    <BrowserRouter>
                        <AppRoutes />
                    </BrowserRouter>

                    <Toaster
                        position="bottom-center"
                        reverseOrder={true}
                        // gutter={8}
                    />

                    <ToastContainer
                        position="top-right"
                        autoClose={4000}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />

                    {/* <ReactQueryDevtools initialIsOpen={false} position='bottom-right' /> */}
                </CoreStates>
            </QueryClientProvider>

        </ProviderState>
    );
}
export default App;
