import { API_HOST_URL } from "../../config";
import { endpoints } from "../../config/endpoints";
import { useMutation } from '@tanstack/react-query';
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/shortcuts";
// import { useState } from "react";

// const USERSROLE_QUERY_KEY = 'role_users'

// export function useUsersRoleList(defaultParam = '', enabled = true) {
//     const [param, setParam] = useState(null);
//     const { isLoading, data } = useQuery([USERSROLE_QUERY_KEY, { param: param, defaultParam }], async (key) => {
//         const param = key.queryKey[1].param;
//         const defaultParam = key.queryKey[1].defaultParam;
//         try {
//             const { accessToken } = getTokens();
//             const param_url = param ? param : defaultParam;
//             const res = await fetchJson(`${API_HOST_URL}/${endpoints.users.list}${param_url}`, {
//                 headers: { 'Authorization': `Bearer ${accessToken}` },
//             }, true)
//             return await res.json()

//         } catch (err) {
//             return { success: false, detail: err };
//         }
//     }, {
//         cacheTime: Infinity,
//         staleTime: 30_000,
//         enabled: enabled,
//     })
//     return {
//         data: data,
//         isLoading: isLoading,
//         filterList: async (value) => setParam(value),
//     }
// }




export function useFilterUsers() {
    const { accessToken } = getTokens();
    const mutation = useMutation((param) => fetchJson(`${API_HOST_URL}/${endpoints.users.lite}${param}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }, true))

    return {
        filterUsers: async (param) => {
            try {
                const res = await mutation.mutateAsync(param);
                return await res.json();
            } catch (err) {
                return {
                    success: false,
                    error: 'something wents wrong.'
                }
            }
        },
        isFilterIsLoading: mutation.isLoading,
    }
}

