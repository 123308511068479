import { Container } from '@mui/material'
import React, { useState } from 'react'
import PageBreadcrumbs from '../../../components/Common/Breadcrumbs'
import { Helmet } from 'react-helmet'
import DocketMaintenanceAltForm from '../../../components/Forms/DocketMaintenanceForm/AltLayout'
import { useDocketDetail, useMaintenanceDocket } from '../../../hooks/docket'

import MaintanceDocketInfoModal from '../../../components/Models/MaintanceDocketInfoModal'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'

export default function MaintenanceDocketUpdatePage() {
    const { handleDocketAction, docketActionIsLoading } = useMaintenanceDocket('PATCH');
    const [modal, setModal] = useState(false)
    const [docData, setDocData] = useState(null);

    const { id } = useParams();
    const { docketData, docketIsLoading } = useDocketDetail(id);
    // console.log(docketData)
    const handleSubmit = async (values, { resetForm }) => {
        // console.log('Values :', values)
        const toastId = toast.loading('Processing...');
        const {
            provider,
            circuit_type,
            link_type,
            order_id,

            location_a,
            pin_a,
            mobile_a,
            port_details_a,
            address_a,
            city_a,
            post_a,
            area_a,
            district_a,
            latitude_a,
            longitude_a,

            location_b,
            pin_b,
            mobile_b,
            port_details_b,
            address_b,
            city_b,
            post_b,
            area_b,
            district_b,
            latitude_b,
            longitude_b,
            // area,
            // district,

            // lc_contact_persion,
            // lc_mobile_no,
            // customer_type,
            // type_of_connectivity,

            // ofc_color,
            // ofc_distance,
            // latitude,
            // longitude,

            fiber_team_leader,
            technician,
            third_party_docket_no,
            problem,
            hirer_name,
            comment,
            docketed_by,
            docketed_by_contact,

            is_scheduled,
            scheduled_at,
            status,
        } = values;

        const data = {
            circuit: {
                provider: provider,
                hirer_name: hirer_name,
                is_maintenance: true,
                

                circuit_type: circuit_type,
                link_type: link_type,

                location_a: location_a,
                pin_a: pin_a,
                mobile_a: mobile_a,
                port_details_a: port_details_a,
                address_a: address_a,
                city_a: city_a,
                post_a: post_a,
                area_a: area_a,
                district_a: district_a,
                latitude_a: latitude_a,
                longitude_a: longitude_a,

                location_b: location_b,
                pin_b: pin_b,
                mobile_b: mobile_b,
                port_details_b: port_details_b,
                address_b: address_b,
                city_b: city_b,
                post_b: post_b,
                area_b: area_b,
                district_b: district_b,
                latitude_b: latitude_b,
                longitude_b: longitude_b,
                
                // area: area,
                // district: district,

                // lc_contact_persion: lc_contact_persion,
                // lc_mobile_no: lc_mobile_no,

                // customer_type: customer_type,
                // type_of_connectivity: type_of_connectivity,

                // ofc_color: ofc_color,
                // ofc_distance: ofc_distance,
                // latitude: latitude,
                // longitude: longitude,               
            },
            provider: provider,
            hirer_name: hirer_name,
            is_maintenance: true,
            order_id: order_id,

            fiber_team_leader: fiber_team_leader,
            technician: technician,
            third_party_docket_no: third_party_docket_no,
            problem: problem,
            comment: comment,
            docketed_by: docketed_by,
            docketed_by_contact: docketed_by_contact,

            // Scheduled Docket
            is_scheduled: is_scheduled,
            scheduled_at: is_scheduled ? scheduled_at : null,
            status: is_scheduled ? 'Scheduled' : status,

            id: id,
        }

        const res = await handleDocketAction(data);
        if (res.success) {
            toast.success(res.detail, { id: toastId });
            setDocData(res?.data)
            setModal(true)
            resetForm({ values: '' });
        } else {
            toast.error(res.detail, { id: toastId })
        }

    }
    return (
        <>
            <Helmet>
                <title>Update Maintenance Docket</title>
            </Helmet>
            <Container maxWidth="xxl" disableGutters>
                <PageBreadcrumbs
                    title="Update Maintenance Docket"
                    alt='Update'
                    history={[{ name: 'Docket', to: '/docket' }, { name: id, to: `/docket/${id}`}]}
                />
                <div className='container'>
                    <div className="row">
                        <div className='mt-2'>
                            {docketData && !docketIsLoading &&
                                <DocketMaintenanceAltForm
                                    data={docketData?.data}
                                    btnLabel='Update Docket'
                                    isLoading={docketActionIsLoading}
                                    handleSubmit={handleSubmit}
                                />
                            }

                            {docData &&
                                <MaintanceDocketInfoModal
                                    modal={modal}
                                    setModal={setModal}
                                    docData={docData}
                                    headerTitle='Maintenance Docket has been updated!'
                                />
                            }
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}
