// import { formatDateTime } from "./shortcuts";

export const checkHasValue = (arr, value) => arr?.indexOf(value) > - 1


export function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
}

export function getCurrentDate(separator = '') {
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
}

export const truncate = (str, max, suffix = ' ...') => str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`;

export const smartTrim = (str, length, delim = ' ', appendix = ' ...') => {
    if (str.length <= length) return str;

    var trimmedStr = str.substr(0, length + delim.length);
    var lastDelimIndex = trimmedStr.lastIndexOf(delim);

    if (lastDelimIndex >= 0) trimmedStr = trimmedStr.substr(0, lastDelimIndex);

    if (trimmedStr) trimmedStr += appendix;
    return trimmedStr;
}


export function capitalize(str) {
    try {
        const lower = str.toLowerCase();
        return str.charAt(0).toUpperCase() + lower.slice(1);
    } catch (error) {
        return str;
    }
}

export const serializeQueryStrings = function (obj) {
    const str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p) && obj[p]) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

// export const secondsToHMS = (secs) => {
//     let sec_num = parseInt(secs, 10)
//     let hours = Math.floor(sec_num / 3600)
//     let minutes = Math.floor(sec_num / 60) % 60
//     let seconds = sec_num % 60

//     return [hours, minutes, seconds]
//         .map(v => v < 10 ? "0" + v : v)
//         .filter((v, i) => v !== "00" || i > 0)
//         .join(":")
// }
export const minusTAT = (source_hms, h = 0, m = 0, s = 0) => {
    if (!source_hms) {
        return '-'
    }
    let hms = source_hms.split(':');
    // console.log(hms)
    let hours = parseInt(hms[0]) - h
    let minutes = parseInt(hms[1]) - m
    let sec = parseInt(hms[2]) - s
    return hours + ":" + minutes + ":" + sec;
}



export const secondsToHMS = (seconds, method = 'num') => {
    if (isNaN(seconds)) {
        return "00:00";
    }
    function secondsToDhms(seconds) {
        seconds = Number(seconds);
        var d = Math.floor(seconds / (3600 * 24));
        var h = Math.floor(seconds % (3600 * 24) / 3600);
        var m = Math.floor(seconds % 3600 / 60);
        var s = Math.floor(seconds % 60);

        var dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
        var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
        var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
        var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
        return dDisplay + hDisplay + mDisplay + sDisplay;
    }

    function secondsToHMS(seconds) {
        var hours = Math.floor(seconds / 3600);
        var minutes = Math.floor((seconds % 3600) / 60);
        var secs = Math.floor((seconds % 3600) % 60);
        return hours + ":" + minutes + ":" + secs;
    }
    // console.log(secondsToHms(seconds))

    if (method === 'words') {
        return secondsToDhms(seconds)
    } else if (method === 'num') {
        return secondsToHMS(seconds)
    } else if (method === 'test') {
        // console.log(secondsToHms(seconds))
        // return secondsTohms2(seconds)
    } else {
        return secondsToHMS(seconds)
    }
};

export const formatNumberToHMS = (num) => {
    try {
        const numberStr = num.toString()
        const hms = `${numberStr.substring(2, 0)}:${numberStr.substring(4, 2)}:${numberStr.substring(6, 4)}`
        return hms
    } catch (error) {
        return '00:00:00'
    }

}

export const HMSToSeconds = (hms) => {
    // console.log('HMS SEC-', hms)
    // var hms = '02:04:33';   // your input string
    if (hms) {
        const a = hms.split(':'); // split it at the colons

        // minutes are worth 60 seconds. Hours are worth 60 minutes.
        const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
        return parseFloat(seconds)
    } else {
        return '----------'
    }
}

export const isEmptyArr = (obj) => {
    try {
        return Object.keys(obj).length === 0
    } catch (error) {
        return true
    }

}
export const makeSlug = (slug) => {
    return slug.toLowerCase().replace(/[^\w-]+/g, '-')
}
