import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import TextField from '../Common/Fields/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button, Typography } from '@mui/material';
import SelectField from '../Common/Fields/SelectField';
import { formatUserOptionsList, useUser, useUsersList } from '../../hooks/user';
import { useFilterUsers } from '../../hooks/user/roles';
import CheckboxField from '../Common/Fields/CheckboxField';
import MaterialForm from '../DocketStatusModal/MaterialForm';
import VehiclesForm from '../DocketStatusModal/VehiclesForm';


const FiberTechnicianDropdown = ({ formik, user }) => {


    const { filterUsers, isFilterIsLoading } = useFilterUsers()
    const [ftDatas, setFtDatas] = useState([]) // Fiber Technician *
    const handleFTLChange = async (value) => {
        // Fiber Team Leader Change
        formik.setFieldValue('fiber_team_leader', value)
        const data = await filterUsers(`?user_role=FT&parent_role=${value}`)
        // setFtDatas(data)
        // console.log('--->',data)
        if (data.success) {
            setFtDatas(data.data)
        } else {
            setFtDatas([])
        }
    }
    useEffect(() => {
        formik.values.fiber_team_leader && handleFTLChange(formik.values.fiber_team_leader)
    }, [formik.values.fiber_team_leader])
    return <>

        <div className="col-md-6 mb-2">
            <Typography>Technician 1 {formik.values.technician && <small className='text-primary' role='button' onClick={() => formik.setFieldValue('technician', null)}>Clear</small>}</Typography>

            <SelectField
                name='technician'
                options={ftDatas.map(formatUserOptionsList)}
                value={ftDatas.map(formatUserOptionsList).filter(function (option) {
                    return option.value === formik.values.technician;
                })}
                onChange={(e) => formik.setFieldValue('technician', e.value)}
                isLoading={isFilterIsLoading}
                // isDisabled={ftDatas.length === 0}
                // isDisabled={disabled}
                isDisabled={ftDatas.length === 0 || !user?.permissions?.dockets?.can_change_tech}
                isClearable={false}
            />
        </div>
        <div className="col-md-6 mb-2">
            <Typography>Technician 2 {formik.values.technician && <small className='text-primary' role='button' onClick={() => formik.setFieldValue('technician2', null)}>Clear</small>}</Typography>

            <SelectField
                name='technician2'
                options={ftDatas.map(formatUserOptionsList)}
                value={ftDatas.map(formatUserOptionsList).filter(function (option) {
                    return option.value === formik.values.technician2;
                })}
                onChange={(e) => formik.setFieldValue('technician2', e.value)}
                isLoading={isFilterIsLoading}
                // isDisabled={ftDatas.length === 0}
                // isDisabled={disabled}
                isDisabled={ftDatas.length === 0 || !user?.permissions?.dockets?.can_change_tech}
                isClearable={false}
            />
        </div>
    </>
}


export default function FTLUpdateForm({ data, isLoading, handleSubmit, setModal }) {
    const { user } = useUser();

    const validate = Yup.object({
        remarks: Yup.string().required('Remarks is required.'),
        fiber_team_leader: Yup.number().required('Team Leader is required.'),
        // Vehicles
        // vehicle1: Yup.string().nullable().when("status", {
        //     is: (val) => val === 'Hold',
        //     then: Yup.string().required('Vehicle 1 is required.').nullable()
        // }),
        // vehicle1_start_km: Yup.number().required('Start KM is required.'),
        // vehicle1_end_km: Yup.number().required('End KM is required.'),
        // vehicle2_start_km: Yup.number().required('Start KM is required.'),
        // vehicle2_end_km: Yup.number().required('End KM is required.'),
        files: Yup
            .mixed()
            .test("fileType", "Unsupported File Format", (value) => {
                if (value && value.length > 0) {
                    for (let i = 0; i < value.length; i++) {
                        if (value[i].type !== "image/png" && value[i].type !== "image/jpg" && value[i].type !== "image/jpeg" && value[i].type !== "video/mp4") {
                            return false;
                        }
                    }
                }
                return true;
            })
            .test("fileSize", "File Size is too large", (value) => {
                if (value && value?.length > 0) {
                    for (let i = 0; i < value.length; i++) {
                        // console.log(`${value[i].name}`, `${value[i].size}`)
                        //  Byte Size
                        if (value[i].size > 20154227) {
                            // 2154227 - 2 MB
                            // 5154227 - 5 MB
                            // 15154227 - 15 MB
                            // 20154227 - 20 MB
                            // 25154227 - 25 MB
                            return false;
                        }
                    }
                }
                return true;
            }),
    })
    const fiber_tl = useUsersList('?user_role=FTL');  // Fiber Team Leader *

    return (
        <Formik
            initialValues={{
                "fiber_team_leader": data.fiber_team_leader ?? null,
                "technician": data?.technician ?? null,
                "technician2": data?.technician2 ?? null,
                "remarks": '',
                "send_sms": false,
                "files": null,
                
                // Vehicle States
                "vehicle1": null,
                "vehicle1_start_km": 0,
                "vehicle1_end_km": 0,
                "vehicle1_to_pop": false,
                "vehicle2": null,
                "vehicle2_start_km": 0,
                "vehicle2_end_km": 0,
                "vehicle2_to_pop": false,
                // Material Detail.
                "materials": [],
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    {/* {console.log(formik)} */}
                    <div className='row' id='modalFOrm'>
                        <div className="col-md-12 mb-2">
                            <Typography>Fiber Team Leader <span className='req'>*</span></Typography>
                            <SelectField
                                name='fiber_team_leader'
                                options={fiber_tl?.usersData?.data?.map(formatUserOptionsList)}
                                value={fiber_tl?.usersData?.data?.map(formatUserOptionsList).filter(function (option) {
                                    return option.value === formik.values.fiber_team_leader;
                                })}
                                onChange={(e) => {
                                    formik.setFieldValue('fiber_team_leader', e.value)
                                    formik.setFieldValue('technician', null)
                                    formik.setFieldValue('technician2', null)
                                }}
                                isLoading={fiber_tl?.usersIsLoading}
                                isDisabled={!user?.permissions?.dockets?.can_change_tl}

                            />
                        </div>

                        {/* CHANGE TECHNICIAN */}
                        {/* {renderFiberTechnicianDropdown(formik)} */}
                        <FiberTechnicianDropdown
                            formik={formik}
                            user={user}
                        />


                        <div className="col-md-12 mb-4">
                            {/* HOLD VEHICLE FORM VIEW */}
                            <VehiclesForm formik={formik} />

                            {user?.permissions?.dockets?.can_add_docket_materials && <MaterialForm formik={formik} />}
                            <TextField label='Remarks *' size="small" type="text" name='remarks' placeholder='Remarks' sx={{ mt: 2 }} />

                            <div className='row mt-2'>
                                <div className="col-md-5 mb-2">
                                    <Typography variant='caption'>Reference Photo / Video </Typography>
                                    <input
                                        className='form-control'
                                        name="files"
                                        type="file"
                                        accept=".jpg, .jpeg, .png, .mp4, .mpeg"
                                        multiple
                                        onChange={(event) => {
                                            formik.setFieldValue("files", Array.from(event.target.files));
                                        }}
                                    />
                                </div>
                            </div>
                            <CheckboxField label='Send SMS' name='send_sms' options={['Send SMS']} />


                        </div>
                        {formik.submitCount >= 1 && formik.errors && Object.entries(formik.errors).map(([key, value]) =>
                            <Alert severity="error" sx={{ mt: 1, mb: 1 }} key={key}>{value}</Alert>
                        )}
                    </div>

                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        type="submit"
                        disableElevation
                    >
                        Update
                    </LoadingButton>
                    <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>


                </Form>
            )}
        </Formik>
    )
}
