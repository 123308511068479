// export const PROBLEM_OPTIONS = [
//     { 'label': 'problem1', value: 'problem1' },
//     { 'label': 'problem2', value: 'problem2' }
// ]
// export const STATUS_OPTIONS = [
//     { 'label': 'Only Update', value: 'Update' },
//     { 'label': 'Hold', value: 'Hold' },
//     { 'label': 'Cancelled', value: 'Cancelled' },
// ]
// export const STATUS_OPTIONS_ALT = [
//     { 'label': 'Resume', value: 'Resume' },
//     { 'label': 'Only Update', value: 'Update' },
// ]
export const STATUS_FILTER_OPTIONS = [
    { 'label': 'Pending', value: 'Pending' },
    { 'label': 'Hold', value: 'Hold' },
    { 'label': 'Resolved', value: 'Resolved' },
    { 'label': 'Cancelled', value: 'Cancelled' },
    { 'label': 'Closed', value: 'Closed' },
    { 'label': 'Scheduled', value: 'Scheduled' },
]


export const DOCKET_REQUESTS_STATUS_FILTER_OPTIONS = [
    { 'label': 'All', value: '' },
    { 'label': 'Pending', value: 'Pending' },
    { 'label': 'Accept', value: 'Accept' },
    { 'label': 'Cancel', value: 'Cancel' },
]
export const DOCKET_UPDATE_REQUESTS_FILTER_OPTIONS = [
    { 'label': 'All', value: '' },
    { 'label': 'Hold Requests', value: 'Hold' },
    { 'label': 'Close Requests', value: 'Close' },
]

export const DOCKET_LM_STATUS = [
    { 'label': 'Team on the way', value: 'Team on the way' }, 
    { 'label': 'WIP (Progress)', value: 'WIP' }, 
    { 'label': 'Waiting for Confirmation', value: 'Waiting for Confirmation' },
]


