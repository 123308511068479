import React from 'react'
import Modal from 'react-modal'
import { toast } from 'react-toastify';
import { useUser } from '../../../hooks/user';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '700px',
        width: '100%'
    },
    overlay: { zIndex: 1000 }
};
export default function GreetPreviewModal({ modal, setModal, docData }) {
    const { user } = useUser();

    function CopyToClipboard(containerid = 'table') {
        var range = document.createRange();
        range.selectNode(document.getElementById(containerid));
        window.getSelection().removeAllRanges(range);
        window.getSelection().addRange(range);
        document.execCommand("copy");
        toast.info('Copied to clipboard');
        // alert("Text Copied to clipboard");
    }
    const renderDocketInfo = (status) => {
        return <>
            <table className='table m-0' id='table'>
                <tbody>
                    <tr>
                        <td className='text-dark text-center' colSpan={2}>
                            <p className='text-center'>
                                Greetings for the day from Meghbela!
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className='text-dark' colSpan={2}>
                            <p>Your docket has been raised successfully. Please find the details below.</p>
                        </td>
                    </tr>
                    <tr>
                        <th>Docket ID:</th>
                        <td className='text-dark fw-bold'>*{docData?.code}*</td>
                    </tr>
                    <tr>
                        <th>Status:</th>
                        <td className='text-dark'> {docData?.status}</td>
                    </tr>
                    <tr>
                        <th>Problem:</th>
                        <td className='text-dark'> {docData?.get_problem?.title}</td>
                    </tr>
                    <tr>
                        <th>Circuit ID:</th>
                        <td className='text-dark'> {docData?.get_circuit?.code}</td>
                    </tr>
                    <tr>
                        <th>Bandwidth:</th>
                        <td className='text-dark'> {docData?.get_circuit?.bandwidth_mb_gig ?? '-'}</td>
                    </tr>
                    <tr>
                        <th>Location A:</th>
                        <td> {docData?.get_circuit.location_a}</td>
                    </tr>
                    <tr>
                        <th>Location B:</th>
                        <td> {docData?.get_circuit?.location_b}</td>
                    </tr>
                    <tr>
                        <td className='text-dark' colSpan={2}>
                            <p className='text-dark'> </p>
                            <p className='text-dark'> Regards, <br />Docket Service Team</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>

    }

    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Docket Greething</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); }}></button>

                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div><button className='btn btn-dark' onClick={() => setModal(false)}> <i className="fa fa-times" aria-hidden="true"></i>Close</button></div>
                        <div>
                            <button className='btn btn-dark' type='button' onClick={() => { CopyToClipboard(); setModal(false); }}> <i className="fa fa-copy" aria-hidden="true"></i>Copy clipboard</button>
                        </div>
                    </div>
                    <hr />
                    {renderDocketInfo(docData.status)}
                    {/* <div className="d-flex justify-content-between align-items-center mt-3">
                        <div></div>
                        <div>
                            <button className='btn btn-dark' onClick={() => setModal(false)}> <i className="fa fa-times" aria-hidden="true"></i>Close</button>
                        </div>
                    </div> */}
                </div>
            </div>
        </Modal>
    )
}
