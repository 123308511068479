/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { formatSelectOptionsList, useFindCircuit, useHirerList } from '../../../hooks/circuit';
import Loader2 from '../../Common/Loader2';
import SelectField from '../../Common/Fields/SelectField';
import TextField from '../../Common/Fields/TextField';
import { CIRCUIT_TYPE_OPTIONS, STATUS_FILTER_OPTIONS, LINK_TYPE_OPTIONS } from '../Options/CircuitForm';
import { useProviderList } from '../../../hooks/core/provider';
import ScheduleExpiryModal from '../../Models/ScheduleExpiryModal';
import { formatDateTime } from '../../../utils/shortcuts';
import UnderMaintenanceModal from '../../Models/UnderMaintenanceModal';
import TextAreaField from '../../Common/Fields/TextAreaField';
import { useUser } from '../../../hooks/user';
// import moment from 'moment';

export default function CircuitDetail({ formik, data }) {
    const { user } = useUser();
    const { hirerData, HirerIsLoading } = useHirerList();
    const { providersData, providerIsLoading } = useProviderList()
    const { handleFindCircuit, isFindCircuitLoading } = useFindCircuit();
    const [hasCustomerId, setHasCutomerId] = useState(false)
    const [expiryDateModal, setExpiryDateModal] = useState(false)
    const [underMaintenanceModal, setUnderMaintenanceModal] = useState(false)

    const checkCustomerId = async (value) => {
        let res = await handleFindCircuit(value, 'customer_id');
        res.success ? setHasCutomerId(true) : setHasCutomerId(false)
    }
    useEffect(() => {
        Object.keys(data).length === 0 && checkCustomerId(formik.values.customer_id)
    }, [formik.values.customer_id])

    const renderCustIdCheckComp = () => {
        if (Object.keys(data).length === 0) {
            return <>
                {isFindCircuitLoading && <Loader2 />}
                {hasCustomerId && <span className='text-danger'>Error: Customer Record with this ID Already Exists.</span>}
            </>
        }
    }
    return (
        <div className="card">
            <div className="card-body stepFormDetails">
                <div className="row">
                    <div className='col-12'>
                        <h3 className='text-center display-6 mb-2 text-primary' style={{ 'fontSize': '22px' }}>CIRCUIT DETAILS</h3>

                    </div>
                    <div className="col-md-3 mb-4">
                        <Typography variant='caption'>Circuits For <span className='req'>*</span></Typography>

                        <SelectField
                            name='provider'
                            options={providersData?.data.map(formatSelectOptionsList)}
                            value={providersData?.data.map(formatSelectOptionsList).filter(function (option) {
                                return option.value === formik.values.provider;
                            })}
                            onChange={(e) => formik.setFieldValue('provider', e.value)}
                            isLoading={providerIsLoading}
                        />
                    </div>
                    <div className="col-md-3 mb-4">
                        <Typography variant='caption'>Circuit Type <span className='req'>*</span></Typography>
                        <SelectField
                            name='circuit_type'
                            options={CIRCUIT_TYPE_OPTIONS}
                            value={CIRCUIT_TYPE_OPTIONS.filter(function (option) {
                                return option.value === formik.values.circuit_type;
                            })}
                            onChange={(e) => formik.setFieldValue('circuit_type', e.value)}
                        />

                    </div>
                    <div className="col-md-2 mb-4">
                        <Typography variant='caption'>Link Type <span className='req'>*</span></Typography>
                        <SelectField
                            name='link_type'
                            options={LINK_TYPE_OPTIONS}
                            isSearchable={false}
                            value={LINK_TYPE_OPTIONS.filter(function (option) {
                                return option.value === formik.values.link_type;
                            })}
                            onChange={(e) => formik.setFieldValue('link_type', e.value)}
                        />
                    </div>

                    <div className="col-md-2 mb-4">
                        <Typography variant='caption'>Hirer Name <span className='req'>*</span></Typography>
                        <SelectField
                            name='hirer_name'
                            options={hirerData?.data?.map(formatSelectOptionsList)}
                            value={hirerData?.data?.map(formatSelectOptionsList).filter(function (option) {
                                return option.value === formik.values.hirer_name;
                            })}
                            onChange={(e) => formik.setFieldValue('hirer_name', e.value)}
                            isLoading={HirerIsLoading}
                        />
                    </div>
                    <div className="col-md-2 mb-4">
                        <Typography variant='caption'>Status <span className='req'>*</span></Typography>
                        <SelectField
                            name='status'
                            options={STATUS_FILTER_OPTIONS}
                            isSearchable={false}
                            value={STATUS_FILTER_OPTIONS.filter(function (option) {
                                return option.value === formik.values.status;
                            })}
                            onChange={(e) => {
                                if (e.value === 'Under Termination') {
                                    setExpiryDateModal(true)
                                } else if (e.value === 'Under Maintenance') {
                                    setUnderMaintenanceModal(true)
                                }
                                return formik.setFieldValue('status', e.value)
                            }}
                        />

                        {expiryDateModal && <ScheduleExpiryModal
                            formik={formik}
                            modal={expiryDateModal}
                            setModal={setExpiryDateModal}
                        />}
                        {underMaintenanceModal && <UnderMaintenanceModal
                            formik={formik}
                            modal={underMaintenanceModal}
                            setModal={setUnderMaintenanceModal}
                        />}

                        <small className='clickable_text' title='Click here to reschedule'>{formik.values.status === 'Under Termination' && formik.values.expiry_date ? `Scheduled on ${formatDateTime(formik.values.expiry_date.toString(), 'DD-MM-YYYY')}` : ''} </small>

                    </div>
                    <div className="col-md-4">
                        <TextField label='Customer ID *' type="text" name='customer_id' placeholder='Customer ID 1' sx={{ mb: 3 }} />
                        {renderCustIdCheckComp()}

                    </div>
                    <div className="col-md-4">
                        <TextField label='Customer ID 2 (Optional)' type="text" name='customer_id_alt' placeholder='Customer ID 2' sx={{ mb: 3 }} />
                    </div>

                    <div className="col-md-4">
                        <TextField label='3rd Party Circuit ID' type="text" name='third_party_circuit_id' placeholder='3rd Party Circuit ID' sx={{ mb: 3 }} />
                    </div>

                    <div className="col-md-4">
                        <TextField label='Order No' type="text" name='order_no' placeholder='Order No' sx={{ mb: 3 }} />
                    </div>
                    <div className="col-md-4">
                        <TextField label='Service No' type="text" name='service_no' placeholder='Service No' sx={{ mb: 3 }} />
                    </div>
                    <div className="col-md-4">
                        <TextField label='RA No' type="text" name='ra_no' placeholder='RA No' sx={{ mb: 3 }} />
                    </div>
                    {user?.user_role === 'EXP' && <div className="col-md-12 mt-2">
                        <TextAreaField label='Comments *' type="text" name='log_comment' placeholder='Comments' sx={{ mb: 3 }} />
                    </div>}
                </div>
            </div>
        </div>
    )
}
