import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { API_HOST_URL } from "../../config";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/shortcuts";
import {
    DOCKETS_QUERY_KEY,
    DOCKET_HOLD_VEHICLE_QUERY_KEY,
    LAST_DOCKET_INFO,
} from "./query_keys";




// Dockets Export API
const getDocketsExport = async (key) => {
    const param = key.queryKey[1].param ?? null;
    const defaultParam = key.queryKey[1].defaultParam;
    try {
        const { accessToken } = getTokens();
        if (param) {
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.docket.export}${param}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()
        } else if (defaultParam) {
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.docket.export}${defaultParam}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()
        } else {
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.docket.export}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()
        }

    } catch (err) {
        return {
            success: false,
            detail: err
        };
    }
}

export function useDocketExport(defaultParam = null, enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([DOCKETS_QUERY_KEY, { param: param, defaultParam }], getDocketsExport, {
        cacheTime: 0,
        staleTime: 0,
        // cacheTime: Infinity,
        // staleTime: 30_000, // ms
        enabled: enabled,
        // refetchOnMount: true, // ms
    })
    return {
        docketsData: data,
        docketsIsLoading: isLoading,
        handleParam: async (value) => setParam(value),
    }
}


export function useDocketHoldVehicles(method) {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation(
        (data) =>
            fetchJson(
                `${API_HOST_URL}/${endpoints.docket.update_hold_vehicle}${method === "PATCH" ? "/" + data.id : ""}`,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(data),
                },
                true,
            ),
        {
            retry: 2,
        },
    );
    return {
        handleDocketBackToPOP: async (data) => {
            try {
                const res = await mutation.mutateAsync(data);
                const resData = await res.json();
                if (resData.success) {
                    await queryClient.invalidateQueries([DOCKETS_QUERY_KEY]);
                }
                return resData;
            } catch (err) {
                return {
                    success: false,
                    detail: err,
                };
            }
        },

        isUpdatingBackToPOP: mutation.isLoading,
    };
}

export function useDocketHoldVehiclesDetail(defaultParam = '', enabled=true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([DOCKET_HOLD_VEHICLE_QUERY_KEY, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.docket.update_hold_vehicle}${param_url}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()

        } catch (err) {
            return { success: false, detail: err };
        }
    }, {
        // cacheTime: Infinity,
        // staleTime: 30_000,
        cacheTime: 0,
        staleTime: 1,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        enabled: enabled,
    })
    return {
        holdVehicleData: data,
        isLoadingHoldVehicleData: isLoading,
        filterHoldVehicleList: async (value) => setParam(value),
    }
}


// Last Docket Info
export function useLastDocketInfo(defaultParam = '', enabled=false) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([LAST_DOCKET_INFO, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.docket.last_docket_info}${param_url}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()

        } catch (err) {
            return { success: false, detail: err };
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000,
        // cacheTime: 0,
        // staleTime: 1,
        // refetchOnWindowFocus: true,
        // refetchOnMount: true,
        enabled: enabled,
    })
    return {
        lastDocketData: data,
        isCheckingLastDocket: isLoading,
        fetchLastDocket: async (value) => setParam(value),
    }
}