import React, { useEffect, useState } from 'react'
import { useClient } from '../../hooks/core/utils';
import moment from 'moment';
import FooterMenu from './FooterMenu';

export default function Footer() {
    const { getClient } = useClient();
    const [time, setTime] = useState(moment().utcOffset('+05:30').format('h:mm:ss a'))
    useEffect(() => {
        const interval = setInterval(() => {
          setTime(moment().utcOffset('+05:30').format('h:mm:ss a'));
        }, 1000);
        return () => clearInterval(interval);
      }, []);
    return (
        <>
            <FooterMenu />
            <div className="footerMainSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="text-center text-md-start">
                                <p className="mb-0">@ {new Date().getFullYear()} Meghbela. Dept. of Docket Service & Operation. All Rights Reserved. </p>
                            </div>
                        </div>
                        <div className="col-md-6 text-center text-md-end">
                            <p className="mb-0">Your Login IP Address {getClient?.ip ?? '0.0.0.0'} | Time: {time}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
