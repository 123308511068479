import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
// import Divider from '@mui/material/Divider';
import { Link as RouterLink } from 'react-router-dom';

// function handleClick(event) {
//     event.preventDefault();
//     console.info('You clicked a breadcrumb.');
// }

export default function PageBreadcrumbs({ title, alt, history, RightComp }) {
    return (
        // <div role="presentation" onClick={handleClick}>
        //     {/* <Divider sx={{ mt: 1 }} /> */}
        //     <Typography variant="h5" className='display-6 mx-1'>{title}</Typography>
        //     <Breadcrumbs aria-label="breadcrumb">
        //         <Link underline="hover" className='mx-1' component={RouterLink} variant="button" color="inherit" to="/">
        //             Home
        //         </Link>\
        //         {history &&
        //             history.map((v, i) => (
        //                 <Link
        //                     underline="hover"
        //                     color="inherit"
        //                     component={RouterLink}
        //                     to={v.to}
        //                     key={i}
        //                 >
        //                     {v.name}
        //                 </Link>
        //             ))
        //         }


        //         <Typography color="text.primary">{alt ? alt : title}</Typography>
        //     </Breadcrumbs>
        //     <Divider sx={{ borderBottomWidth: '0.5px', mt: .5  }}/>
        // </div>
        <div className="row mb-1" role="presentation" >
            <div className="col">
                <div className="page-description d-flex align-items-center">
                    <div className="page-description-content flex-grow-1">
                        <h1>{title}</h1>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" className='mx-1' component={RouterLink} variant="button" color="inherit" to="/">
                                Home
                            </Link>\
                            {history &&
                                history.map((v, i) => (
                                    <Link
                                        underline="hover"
                                        color="inherit"
                                        component={RouterLink}
                                        to={v.to}
                                        key={i}
                                    >
                                        {v.name}
                                    </Link>
                                ))
                            }
                            <Typography color="text.primary">{alt ? alt : title}</Typography>
                        </Breadcrumbs>
                    </div>
                    <div className="page-description-actions d-flex align-items-center align-self-center">
                        {RightComp && <RightComp />}
                    </div>
                </div>
            </div>
        </div>
    )
}
