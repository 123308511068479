import { useQuery } from "@tanstack/react-query";
import { getTokens } from "../../utils/shortcuts";
import { fetchJson } from "../../libs/api";
import { endpoints } from "../../config/endpoints";
import { API_HOST_URL } from "../../config";

const PROVIDERS_QUERY_KEY = 'providers'


// Get Provider List
export function useProviderList() {
    const { isLoading, data } = useQuery([PROVIDERS_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const data = await fetchJson(`${API_HOST_URL}/${endpoints.core.provider_list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return data;
        } catch (err) {
            return {
                success: false,
                detail: err
            };
        }
    }, {
        // cacheTime: Infinity,
        // staleTime: 30_000, // ms
        cacheTime: 0,
        staleTime: 1,
    })
    return { providersData: data, providerIsLoading: isLoading }
}

