import { Typography } from '@mui/material';
import React from 'react'
import SelectField from '../../Common/Fields/SelectField';
import TextField from '../../Common/Fields/TextField';
import { OFC_COLOR_OPTIONS } from '../Options/CircuitForm';

export default function FiberDetail({ formik }) {
    return (
        <div className="card">
            <div className="card-body stepFormDetails">
                <div className="row">
                    <div className='col-12'>
                        <h3 className='text-center display-6 mb-4 text-primary' style={{ 'fontSize': '22px' }}>FIBER DETAILS</h3>
                    </div>

                    
                    <div className="col-md-12 mb-4">
                        {/* <Typography>OFC Color <span className='req'>*</span></Typography> */}
                        <Typography variant='caption'>OFC Color</Typography>
                        <SelectField
                            name='customer_type'
                            options={OFC_COLOR_OPTIONS}
                            isSearchable={false}
                            value={OFC_COLOR_OPTIONS.filter(function (option) {
                                return option.value === formik.values.ofc_color;
                            })}
                            onChange={(e) => formik.setFieldValue('ofc_color', e.value)}
                        />
                    </div>
                    
                    <div className="col-md-4">
                        <TextField label='OFC/Cable/Drum No' type="text" name='ofc_cable_drum_no' placeholder='OFC / Cable / Drum No' sx={{ mb: 3 }} />
                    </div>
                    <div className="col-md-4">
                        <TextField label='OFC Distance In K.M' type="text" name='ofc_distance' placeholder='OFC Distance In K.M' sx={{ mb: 3 }} />
                    </div>
                    <div className="col-md-4">
                        <TextField label='Pole Joint No' type="text" name='pole_joint_no' placeholder='Pole Joint No' sx={{ mb: 3 }} />
                    </div>
                    <div className="col-md-4">
                        <TextField label='Address' type="text" name='address' placeholder='Address' sx={{ mb: 3 }} />
                    </div>
                </div>
            </div>
        </div>

    )
}
